<template>
  <div class="grid">
    <Navigation />
    <main class="content">
      <section class="contact">
        <h1 class="h2">CONTACT</h1>
        <address class="contact__info">
          <a href="mailto:info@humo.org">info@humo.org</a>
          <a href="tel:+41764459564">+41 76 445 95 64</a>
          <a href="https://www.instagram.com/humocontemporary"
            >@humocontemporary</a
          >
        </address>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "Contact",
  components: {
    Footer,
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-rows: 1fr min-content;
  height: 100vh;
}

.contact {
  .contact__info {
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;
  }
}
</style>
