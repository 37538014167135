<template>
  <footer>
    <p>© HUMO AG {{ date }}</p>
    <div class="identity">
      <p>Web</p>
      <img
        src="@/assets/logos/nobu_logo-black.svg"
        height="13"
        alt="Studio Nobu"
      />
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  box-sizing: border-box;
  width: 100vw;
  height: auto;
  padding: 2rem 2rem 4rem 2rem;
  // min-height: 10vh;

  p {
    font-size: 1rem;
  }

  .identity {
    display: flex;
    align-items: flex-start;
    width: auto;

    margin-top: 1rem;

    img {
      margin-left: 5px;
      margin-top: 3px;
    }
    @-moz-document url-prefix() {
      img {
        margin-top: 1px;
      }
    }
  }
}
@media (min-width: $md) {
  footer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 2rem 5%;

    text-align: left;

    .identity {
      margin-top: 0;
    }
  }
}
</style>
