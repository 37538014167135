<template>
  <nav id="nav">
    <router-link to="/">
      <img src="@/assets/logos/Humo_Lab-black.svg" alt="Humo Lab" />
    </router-link>
    <router-link to="/contact">Contact</router-link>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    color: {
      type: String,
      default: "black",
    },
  },
};
</script>

<style lang="scss">
#nav {
  position: absolute;
  top: 0;
  left: 0;

  // position: absolute;
  box-sizing: border-box;
  padding: 2rem 5%;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  z-index: 8 !important;

  img {
    height: 4rem;
    width: auto;
  }
  a {
    display: block;

    font-size: 1rem;
    font-weight: bold;

    text-transform: uppercase;
    text-decoration: none;
    background-color: transparent;
    // color: $white;
    // mix-blend-mode: difference;

    &:not(:first-child) {
      margin-left: 30px;
    }

    &.router-link-exact-active {
    }
  }
}
</style>
